/**
 * Main application scss file
 */
@import "~@bryntum/demo-resources/scss/example.scss";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "../src/assets/scss/variables";

.selected-row {
  margin-right: 1em;
  font-size: 0.9em;
  line-height: 0.9em;
  text-transform: uppercase;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selected-row span {
  font-size: 1.1em;
  font-weight: 800;
}

// fixes styling bug
.b-theme-stockholm .b-has-label label {
  color: #4f5964;
}

.b-theme-classic .b-has-label label {
  color: #e6e6e6;
}

.b-theme-classic-light .b-has-label label {
  color: #616161;
}

.b-theme-classic-dark .b-has-label label {
  color: #c0c1c2;
}

.b-theme-classic-material .b-has-label label {
  color: #616161;
}

.small-text {
  font-size: 0.7em;
}

.yes-button,
.no-button {
  flex: 1;
  border: none;
  outline: none;
}

[data-column="team"] > div {
  flex: 1;
}

.b-grid-cell.warning {
  color: red;
}

.increase,
.decrease,
.stable {
  padding-right: 2em;
}

.increase:before,
.decrease:before,
.stable:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  position: absolute;
  right: 0.5em;
}

.increase:before {
  content: "\f062";
  color: $success;
}

.decrease:before {
  content: "\f063";
  color: $warning;
}

.stable:before {
  content: "\f111";
}

.demo-header {
  background-color: #009051;
}

.b-grid-row.pavel {
  background: lighten($color: gray, $amount: 45%);
}

.maior {
  background-color: lighten($color: #c8e0f5, $amount: 6%);
}

.menor {
  background-color: lighten(yellow, 35%);
}

.setaCima:before {
  content: "\f062";
  color: $success;
}

.setaCima_html {
  color: $success;
}

.setaBaixo_html {
  color: $danger;
}

.setaCima:before,
.decrease:before,
.igual:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  position: absolute;
  left: 0.5em;
}

.setaBaixo:before {
  content: "\f063";
  color: $danger;
}

.setaBaixo:before,
.decrease:before,
.igual:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  position: absolute;
  left: 0.5em;
}
.igual:before {
  left: 0.5em;
}

.setaCima,
.setaBaixo,
.igual {
  padding-left: 2em;
}


.setaCimaInversa:before {
  content: "\f062";
  color: $danger;
}

.setaCimaInversa_html {
  color: $danger;
}

.setaBaixoInversa_html {
  color: $success;
}

.setaCimaInversa:before,
.decrease:before,
.igual:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  position: absolute;
  left: 0.5em;
}

.setaBaixoInversa:before {
  content: "\f063";
  color: $success;
}

.setaBaixoInversa:before,
.decrease:before,
.igual:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  position: absolute;
  left: 0.5em;
}
.igual:before {
  left: 0.5em;
}

.setaCimaInversa,
.setaBaixoInversa,
.igual {
  padding-left: 2em;
}



.igual:before {
  content: "\f07e";
  color: $gray-600;
}

.order-2 {
  order: 2;
  margin-left: 0.5rem;
}

.text-bold {
  font-weight: 500;
  line-height: 1.2;
}

.loaderContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(247, 247, 247);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 150px;
    height: 150px;
  }
  p {
    text-align: center;
    font-size: 11px;
  }
}

.capitalRelacional {
  position: relative;
  flex: 1 1 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transform: translate(0, 0);
  top: 0;
  left: 0;
  .b-hover {
    background-color: unset !important;
  }
}

.capitalRelacional
  .b-gridbase:not(.b-moving-splitter)
  .b-grid-row:not(.b-group-row).b-hover
  .b-grid-cell:not(.b-focused) {
  background-color: rgba($color: $primary, $alpha: 0.05) !important;
  //background-color: rgba($color: $primary, $alpha: 0.4) !important;
}

.capitalRelacional
  .b-gridbase:not(.b-moving-splitter)
  .b-grid-row:not(.b-group-row).b-hover
  .bg-success.b-grid-cell {
  background-color: darken($color: $success, $amount: 5) !important;
  //background-color: rgba($color: $primary, $alpha: 0.4) !important;
}

.capitalRelacional
  .b-gridbase:not(.b-moving-splitter)
  .b-grid-row:not(.b-group-row).b-hover
  .bg-warning.b-grid-cell {
  background-color: darken($color: $warning, $amount: 5) !important;
  //background-color: rgba($color: $primary, $alpha: 0.4) !important;
}

.capitalRelacional
  .b-gridbase:not(.b-moving-splitter)
  .b-grid-row:not(.b-group-row).b-hover
  .bg-danger.b-grid-cell {
  background-color: darken($color: $danger, $amount: 5) !important;
  //background-color: rgba($color: $primary, $alpha: 0.4) !important;
}

.bg-warning-full {
  background-color: darken($color: $warning, $amount: 10) !important;
}

.capitalRelacional
  .b-gridbase:not(.b-moving-splitter)
  .b-grid-row:not(.b-group-row).b-hover
  .bg-warning-full.b-grid-cell {
  background-color: darken($color: $warning, $amount: 15) !important;
  //background-color: rgba($color: $primary, $alpha: 0.4) !important;
}

.bg-success-full {
  background-color: darken($color: $success, $amount: 10) !important;
}

.capitalRelacional
  .b-gridbase:not(.b-moving-splitter)
  .b-grid-row:not(.b-group-row).b-hover
  .bg-success-full.b-grid-cell {
  background-color: darken($color: $success, $amount: 15) !important;
  //background-color: rgba($color: $primary, $alpha: 0.4) !important;
}

.bg-danger-full {
  background-color: darken($color: $danger, $amount: 10) !important;
}

.capitalRelacional
  .b-gridbase:not(.b-moving-splitter)
  .b-grid-row:not(.b-group-row).b-hover
  .bg-danger-full.b-grid-cell {
  background-color: darken($color: $danger, $amount: 15) !important;
  //background-color: rgba($color: $primary, $alpha: 0.4) !important;
}


.setaCimaDespesas:before {
  content: "\f062";
  color: $warning;
}

.setaCimaDespesas_html {
  color: $warning;
}

.setaBaixoDespesas_html {
  color: $success;
}

.setaCimaDespesas:before,
.decreaseDespesas:before,
.igualDespesas:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  position: absolute;
  left: 0.5em;
}

.setaBaixoDespesas:before {
  content: "\f063";
  color: $success;
}

.setaBaixoDespesas:before,
.decreaseDespesas:before,
.igualDespesas:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  position: absolute;
  left: 0.5em;
}
.igualDespesas:before {
  left: 0.5em;
}

.setaCimaDespesas,
.setaBaixoDespesas,
.igualDespesas {
  padding-left: 2em;
}
/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 3.1.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";

@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

@keyframes nuvemPalavrasAberta {
  from {height: 76px;}
  to {height: calc(100vh - 310px);}
}

@keyframes nuvemPalavrasFechada {
  from {height: calc(100vh - 310px);}
  to {height: 76px;}
}

.btn-outline-secondary-hover {
  color: #fff;
  background-color: #74788d;
  border-color: #74788d;
}

.initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  margin: 0.5em 0.5em 0.5em 0;
  background-color: lightskyblue;
  color: #fff;
}

.pesquisasFeitas {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: lightskyblue;
  color: #fff;
}

.suplicas-ponto-atencao{
  padding-top: 7px !important;
  padding-bottom: 7px;
  font-size: 11px;
}

.painelRealina{
  background: rgb(128, 128, 128);
  // -moz-border-radius: 18px;
  // -webkit-border-radius: 18px;
  // border-radius: 18px;
  // -moz-box-shadow: 2px 3px 5px #808080;
  // -webkit-box-shadow: 2px 3px 5px #808080;
  // box-shadow: 2px 3px 5px #808080;
  // width:400px;
  // min-width:400px;
  // max-width:400px;
  // margin:10px;
  color:#fff;
}
.painelRealina .titulo{
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
}

.border_itens_realina {
  border-right: 1px solid rgba(255, 255, 255, 0.7);
}
.valores_secundarios_realina {
  opacity: .7;
}

.painelRealina .tituloValores{
  font-size: 15px;
}

.painelRealina .percentual{
  font-size: 20px;
  font-weight: bold;
}

.painelRealina .valor{
  font-size: 15px;
  opacity: .7;
}


// Container that the modal scrolls within
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;
    // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
    // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
    // See also https://github.com/twbs/bootstrap/issues/17695
  }
  
  // Shell div to position the modal with bottom padding
  .modal-dialog {
    position: relative;
    width: auto;
    margin: $modal-dialog-margin;
    // allow clicks to pass through for custom click handling to close modal
    pointer-events: none;
  
    // When fading in the modal, animate it to slide down
    .modal.fade & {
      @include transition($modal-transition);
      transform: $modal-fade-transform;
    }
    .modal.show & {
      transform: $modal-show-transform;
    }
  
    // When trying to close, animate focus to scale
    .modal.modal-static & {
      transform: $modal-scale-transform;
    }
  }
  
  .modal-dialog-scrollable {
    height: subtract(100%, $modal-dialog-margin * 2);
  
    .modal-content {
      max-height: 100%;
      overflow: hidden;
    }
  
    .modal-body {
      overflow-y: auto;
    }
  }
  
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: subtract(100%, $modal-dialog-margin * 2);
  }
  
  // Actual modal
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    // counteract the pointer-events: none; in the .modal-dialog
    color: $modal-content-color;
    pointer-events: auto;
    background-color: $modal-content-bg;
    background-clip: padding-box;
    border: $modal-content-border-width solid $modal-content-border-color;
    @include border-radius($modal-content-border-radius);
    @include box-shadow($modal-content-box-shadow-xs);
    // Remove focus outline from opened modal
    outline: 0;
  }
  
  // Modal background
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal-backdrop;
    width: 100vw;
    height: 100vh;
    background-color: $modal-backdrop-bg;
  
    // Fade for backdrop
    &.fade { opacity: 0; }
    &.show { opacity: $modal-backdrop-opacity; }
  }
  
  // Modal header
  // Top section of the modal w/ title and dismiss
  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
    padding: $modal-header-padding;
    border-bottom: $modal-header-border-width solid $modal-header-border-color;
    @include border-top-radius($modal-content-inner-border-radius);
  
    .btn-close {
      padding: ($modal-header-padding-y * .5) ($modal-header-padding-x * .5);
      margin: ($modal-header-padding-y * -.5) ($modal-header-padding-x * -.5) ($modal-header-padding-y * -.5) auto;
    }
  }

  .modal-header .close {
    padding: 1rem 1rem;
    margin: unset !important;
}  
  // Title text within header
  .modal-title {
    margin-bottom: 0;
    line-height: $modal-title-line-height;
  }
  
  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modal-body {
    position: relative;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when there should be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
    padding: $modal-inner-padding;
  }
  
  // Footer (for actions)
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center; // vertically center
    justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
    padding: $modal-inner-padding - $modal-footer-margin-between * .5;
    border-top: $modal-footer-border-width solid $modal-footer-border-color;
    @include border-bottom-radius($modal-content-inner-border-radius);
  
    // Place margin between footer elements
    // This solution is far from ideal because of the universal selector usage,
    // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
    > * {
      margin: $modal-footer-margin-between * .5;
    }
  }
  
  // Scale up the modal
  @include media-breakpoint-up(sm) {
    // Automatically set modal's width for larger viewports
    .modal-dialog {
      max-width: $modal-md;
      margin: $modal-dialog-margin-y-sm-up auto;
    }
  
    .modal-dialog-scrollable {
      height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);
    }
  
    .modal-dialog-centered {
      min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);
    }
  
    .modal-content {
      @include box-shadow($modal-content-box-shadow-sm-up);
    }
  
    .modal-sm { max-width: $modal-sm; }
  }
  
  @include media-breakpoint-up(lg) {
    .modal-lg,
    .modal-xl {
      max-width: $modal-lg;
    }
  }
  
  @include media-breakpoint-up(xl) {
    .modal-xl { max-width: $modal-xl; }
  }
  
  // scss-docs-start modal-fullscreen-loop
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    $postfix: if($infix != "", $infix + "-down", "");
  
    @include media-breakpoint-down($breakpoint) {
      .modal-fullscreen#{$postfix} {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
  
        .modal-content {
          height: 100%;
          border: 0;
          @include border-radius(0);
        }
  
        .modal-header {
          @include border-radius(0);
        }
  
        .modal-body {
          overflow-y: auto;
        }
  
        .modal-footer {
          @include border-radius(0);
        }
      }
    }
  }

  .btn-boa-conversa  label{
    margin-bottom: 0px !important;
}
  // scss-docs-end modal-fullscreen-loop
  
// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";


table {
  overflow: hidden;
}

// tr:hover {
//   background-color: #ffa;
// }

td, th {
  position: relative;
}
// td:hover::after,
// th:hover::after {
//   content: "";
//   position: absolute;
//   background-color: #ffa;
//   left: 0;
//   top: -5000px;
//   height: 10000px;
//   width: 100%;
//   z-index: -1;
// }
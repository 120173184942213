body{
    font-family: "Poppins",sans-serif;
}

// .linha-destaque{
//   font-weight: bold !important;
// }

// .linha-super-destaque{
//   font-weight: bold !important;
//   color:#000 !important;
// }

.linha-destaque-cinema{
  font-weight: bold;
}
.linha-super-destaque-cinema{
  color:#000;
  font-weight:bold;
}

.normal-azul {
  color: #0174DF
}

.normal-laranja {
  color:#FF8000
}


.super-destaque{
  color:#000;
  font-weight:bold;
}

.destaque{
  font-weight: bold;
}

.super-destaque{
  color:#000;
  font-weight:bold;
}

hr {
  @media (max-width: 991.98px) {
    margin-top: .5rem;
    margin-bottom: .5rem;

  }
}
input, textarea, .form-control{
  font-size: 0.8rem;
    @media (max-width: 991.98px) {
      font-size: 16px !important;
  }
}

.unidadesControler{
  min-width: 200px;
  @media (max-width: 991.98px) {
    min-width: 180px;
    width: 180px;
  }
} 

.anoControler{
  min-width: 100px;
  @media (max-width: 991.98px) {
    min-width: 100px;
    width: 100px;
  }
} 

.toolbar{
  .w-sm{
    min-width: 50px;
  }
}

.toolBarVard{
  & .toolbar:first-child{
    flex: 1;
    width: auto;
  }
  .form-control{
    height: calc(1.5em + 0.94rem + 4px);
    @media (max-width: 991.98px) {
      height: calc(1.5em + 0.94rem);
    }
    
  }
  .form-inline > div{
    @media (max-width: 991.98px) {
      margin-bottom: .2rem;
      margin-top: .2rem;
    }
  }
}

.filtroGrupo{
  .form-group{
    margin-bottom: 0;
  }
  .btn-light{
    @media (max-width: 991.98px) {
      height: 41px;
  }
    
  }
}


.pdF{
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.barBrytum{
  border-bottom: 1px solid #d8d9da;
  .form-group{
    @media (max-width: 991.98px) {
      margin-bottom: 0;
    }
  }
  .form-control{
    height: calc(1.5em + 0.94rem + 3px);
    font-size: 14px !important;

  }
}
@keyframes mymove {
  0%{
    transform: rotate(0);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: rotate(90deg);
    opacity: 0;
  }
}
.forcarPaisagem{
  display: none;
  i{
    animation: mymove 3s infinite;
  }
  @media screen and (max-width: 720px) and (orientation:portrait) {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #d8d9da;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100vh;
    z-index: 999999999;


  }
  
}

.b-grid-body-container:focus .b-grid-row.b-selected{
  background-color: rgba($primary, 0.05) !important;
}

.b-grid-row.b-selected{
  background-color: rgba($primary, 0.05) !important;
}

.b-hover{
  background-color: rgba($primary, 0.05) !important;
}

.b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell:not(.b-focused) {
  background-color: rgba($primary, 0.05) !important;
}

.b-mask-text{
  background-color: rgba($primary, 1) !important;
}




.totalizadores{
  .text-muted-span{
    font-size: 80%;
  }
  @media (max-width: 1366.98px) {
    h5{
      font-size: .8rem;
    }
  }
  @media (max-width: 1024.98px) {
    h5{
      font-size: .8rem;
    }
    p{
      font-size: .7rem;
    }

  }
}
.altoZIndex{
  z-index:999 ;
}
.fixWith{
  max-width: 100% !important;
}
.toolbar-card{
  @media (max-width: 991.98px) {
    width: 100%;
  }
}

#layout-wrapper{
  overflow: auto;
  min-height: calc(100vh - 70px);
}
 .layout-wrapper {
   overflow:auto
 }
 
.account-pages{
    height: calc(100vh - 25px);
    align-items: center;
    display: flex;
}
.alinhar-direita div{
  justify-content: flex-end;
}
.form-floating {
    > label {
      right: 0;
      left: auto;
    }
  }
.authcode{
    div{
        display: flex;
        justify-content: space-between;
    }
}

.btnPaginaPrincipal{
    cursor: pointer;
    p{
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      text-overflow: ellipsis;
      height: 38px;

    }
    &:hover{
        background-color: rgba($primary, 0.15);
        
    }
}

.tacasDaContribuicao{
  span{
    font-size: 14px;
  }
  @media (max-width: 1366.98px) {
    h5{
      //font-size: 12px;
      letter-spacing: -0.7px;
      font-size: 90%;
    }
    p{
      letter-spacing: -0.7px;
      font-size: 90%;
    }
    span{
      font-size: 90%;
      letter-spacing: -0.7px;
    }

  }
}

.form-floating {
    position: relative;
    > .form-control,
    > .form-select {
      height: $form-floating-height;
      padding: $form-floating-padding-y $form-floating-padding-x;
    }
    > label {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%; // allow textareas
      padding: $form-floating-padding-y $form-floating-padding-x;
      pointer-events: none;
      border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
      transform-origin: 0 0;
      @include transition($form-floating-transition);
    }
  
    // stylelint-disable no-duplicate-selectors
    > .form-control {
      &::placeholder {
        color: transparent;
      }
  
      &:focus,
      &:not(:placeholder-shown) {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
      }
      // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
      &:-webkit-autofill {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
      }
    }
  
    > .form-select {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  
    > .form-control:focus,
    > .form-control:not(:placeholder-shown),
    > .form-select {
      ~ label {
        opacity: $form-floating-label-opacity;
        transform: $form-floating-label-transform;
      }
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    > .form-control:-webkit-autofill {
      ~ label {
        opacity: $form-floating-label-opacity;
        transform: $form-floating-label-transform;
      }
    }
    // stylelint-enable no-duplicate-selectors
  }
  
  .form-floating {
    position: relative;
  
    > .form-control,
    > .form-select {
      height: $form-floating-height;
      padding: $form-floating-padding-y $form-floating-padding-x;
    }
  
    > label {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%; // allow textareas
      padding: $form-floating-padding-y $form-floating-padding-x;
      pointer-events: none;
      border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
      transform-origin: 0 0;
      @include transition($form-floating-transition);
    }
  
    // stylelint-disable no-duplicate-selectors
    > .form-control {
      &::placeholder {
        color: transparent;
      }
  
      &:focus,
      &:not(:placeholder-shown) {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
      }
      // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
      &:-webkit-autofill {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
      }
    }
  
    > .form-select {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  
    > .form-control:focus,
    > .form-control:not(:placeholder-shown),
    > .form-select {
      ~ label {
        opacity: $form-floating-label-opacity;
        transform: $form-floating-label-transform;
      }
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    > .form-control:-webkit-autofill {
      ~ label {
        opacity: $form-floating-label-opacity;
        transform: $form-floating-label-transform;
      }
    }
    // stylelint-enable no-duplicate-selectors
  }

  .loaderContainer{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color:rgba(247, 247, 247, 0.95);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    
    & > div{
        width: 150px;
        height: 150px;
    }
    p{
        text-align: center;
        font-size: 11px;
    }

}



.titulopage{
  flex: 1;
  overflow:hidden;
}
  
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }


      .fileinput {
        display: inline-block;
        margin-bottom: 9px;
        width: 100%;
      }
      
    
    .fileinput .thumbnail{
        display: inline-block;
        margin-bottom: 10px;
        overflow: hidden;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 100%;
        height: 250px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 0 1px 15px 1px rgba(39,39,39,.1);   
      }
      .fileinput input[type=file] {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    
      .fileinput .avatar-upload{
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }
    
      .fileinput {
        display: inline-block;
        margin-bottom: 9px;
        width: 100%;
      }


#containerB {
        padding: 3em;
        z-index: 99999999999999999;
        width: 100% !important;
        height: 100vh !important;
        min-height: auto;
        flex: 1;
        background-color: rgba(#000, 0.5);
        @media (max-width: 991.98px) {
          padding: 1.5em;
        }
    }
#containerB {
    position: relative;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transform: translate(0, 0);
    top: 0;
    left: 0;
    overflow: hidden;
}

.positon-absolute-br{
  position: absolute;
  width: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: $primary !important;
  outline: none;
  padding: 0;
}


.content-modal-loader{
  min-height: 10em;
  flex: 1 1;
  background-color: #f7f7f7;
  z-index: 99999;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  & > div{
      width: 150px;
      height: 150px;
  }
  p{
      text-align: center;
      font-size: 11px;
  }
}

.b-icon:before {
  font-family: "Font Awesome 5 Pro";
}


.unidadesControler .css-1uccc91-singleValue{
  color: #74788d !important;
}